/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
//import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
//import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
//import Profile from "pages/LandingPages/SetContract/sections/Profile";
//import Posts from "pages/LandingPages/SetContract/sections/Posts";
import Contact from "pages/LandingPages/SetContract/SetContractPages/SetSubject/sections/Contact";
import Footer from "pages/LandingPages/SetContract/SetContractPages/SetSubject/sections/Footer";
import PropTypes from "prop-types";


// Routes
//import routes from "routes";

// Images
//import bgImage from "assets/images/city-profile.jpg";

function SetSubject({ contractData }) {
  return (
    <>
      <MKBox bgColor="white">
        <Contact contractData={contractData} />
        <Footer />
      </MKBox>
    </>
  );
}

SetSubject.propTypes = {
  contractData: PropTypes.object,
  //setContractData: PropTypes.func,
};
export default SetSubject;
