import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
// Import the necessary components here
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/examples/blog2.jpg";
import ContractEntryAmount from "./combox-amount";
import ContractEntryPackage from "./combox-package";
import QualityStandards from "./QualityStandards";
import NumbofParties from "./NumbofParties";
import Invoice from "./Invoice";

const Contact = () => {
  const [contractData, setContractData] = useState({
  parties: {
    numberOfParties: 2,
    partyTitles: ["Importer", "Exporter"],
    partiesData: [
      {
        name: "",
        address: "",
        phoneNumber: 0,
        zipCode: 0,
        walletAddress: "",
      },
      {
        name: "",
        address: "b",
        phoneNumber: 0,
        zipCode: 0,
        walletAddress: "b",
      },
      {
        name: "",
        address: "",
        phoneNumber: 0,
        zipCode: 0,
        walletAddress: "",
      },
      // You can add more parties as needed
    ],
  },
  product: {
    name: "",
    hsCode: 0,
    amount: 0,
    packageType: "package",
    packageAmount: 0,
    invoice: "",
  },
  standard: {
    required: "No",
    list: [],
  },
  steps: {
    stepData: [
      // You can add multiple steps like this
      {
        title: "",
        amount: "",
        payer: "",
        receiver: "",
        paymentCondition: "AfterConfirmation",
        daysAfterConfirmation: "",
        confirmationMethod: "UniqueCodes",
        penalty: "NoPenalty",
        penaltyAmount: "",
        penaltyReceiver: "",
      },
    ],
  },
});

const handleProductNameChange = (event) => {
  const updatedData = {
    ...contractData,
    product: {
      ...contractData.name,
      name: event.target.value,
    },
  };
  setContractData(updatedData);
};

  const handleProductHsCodeChange = (event) => {
    const updatedData = {
      ...contractData,
      product: {
        ...contractData.product,
        hsCode: event.target.value,
      },
    };
    setContractData(updatedData);
  };

  const navigate = useNavigate();
  const handleNextClick = () => {
    navigate("/services/smart-contract/set-1st-party", {
      state: { contractData },
    });
  };
  // useEffect(({ contractData }) => console.log(contractData), [contractData]);


  return (
    <MKBox component="section" py={{ xs: 0, lg: 8 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Set Smart Contract Type-A
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Craft your customized Type-A smart contract effortlessly. Define parties,
                      cargo specifics, and confirmation protocols to streamline your secure
                      international trade agreements.
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      1/3. Contract General Settings
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      Describe your product in detail.
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <NumbofParties
                          contractData={contractData}
                          setContractData={setContractData}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Product's Name is"
                          placeholder="Product Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required={true}
                          value={contractData.product.name}
                          onChange={handleProductNameChange}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Product's HS Code is"
                          placeholder="8508700090"
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required={true}
                          value={contractData.product.hsCode}
                          onChange={handleProductHsCodeChange}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <ContractEntryAmount
                          contractData={contractData}
                          setContractData={setContractData}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <ContractEntryPackage
                          contractData={contractData}
                          setContractData={setContractData}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <Invoice contractData={contractData} setContractData={setContractData} />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <QualityStandards
                          contractData={contractData}
                          setContractData={setContractData}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={10}
                        md={6}
                        justifyContent="flex-start"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton variant="outlined" color="info">
                          Back
                        </MKButton>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton variant="gradient" color="info" onClick={handleNextClick}>
                          Next
                        </MKButton>
                      </Grid>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}


Contact.propTypes = {
  contractData: PropTypes.object,
  //setContractData: PropTypes.func,
};

export default Contact;