// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
//import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";
import { LinkedIn } from "@mui/icons-material";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "SmarterContract",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com",
    },
    {
      icon: <LinkedIn />,
      link: "https://linkedin.com",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "/about-us" },
        { name: "blog", href: "/" },
      ],
    },
    {
      name: "Primary Service",
      items: [
        { name: "Smart Contract Generator", href: "/services/smart-contract" },
        { name: "Contract Types", href: "/services/smart-contract/select-contract" },
      ],
    },
    {
      name: "Secondary Services",
      items: [
        { name: "API Based Contract Monitoring", href: "/services/api-based" },
        { name: "IoT Tracking Package", href: "/service/iot-tracker" },
        { name: "Automated Crypto Payment", href: "/services/auto-crypto" },
      ],
    },
    {
      name: "Technology",
      items: [
        { name: "Blockchain Network", href: "/" },
        { name: "Crypto Coins", href: "/" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} by{" "}
      <MKTypography
        component="a"
        href="/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        SmarterContract
      </MKTypography>
      .
    </MKTypography>
  ),
};
