/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import SmartContract from "layouts/pages/landing-pages/smart-contract";
import APIBased from "layouts/pages/landing-pages/api-based";
import IoTTracker from "layouts/pages/landing-pages/iot-tracker";
import AutoCrypto from "layouts/pages/landing-pages/auto-crypto";
//import Author from "layouts/pages/landing-pages/author";
import SignIn from "layouts/pages/authentication/sign-in";
import SignUp from "layouts/pages/authentication/sign-up";
import SelectContract from "layouts/pages/landing-pages/select-contract";
import Set1stParty from "layouts/pages/landing-pages/set-contract/set-1st-party";
import Set2ndParty from "layouts/pages/landing-pages/set-contract/set-2nd-party";
//import Set3rdParty from "layouts/pages/landing-pages/set-contract/set-3rd-party";
import SetSubject from "layouts/pages/landing-pages/set-contract/set-subject";
import SetCondition from "pages/LandingPages/SetContract/SetContractPages/SetCondition";
//import ContractReview from "pages/LandingPages/SetContract/SetContractPages/ContractReview";
//import ShareContract from "pages/LandingPages/ShareContract";

// Material Kit 2 React routes
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/services/api-based" element={<APIBased />} />
        <Route path="/services/iot-tracker" element={<IoTTracker />} />
        <Route path="/services/auto-crypto" element={<AutoCrypto />} />
        <Route path="/services/smart-contract" element={<SmartContract />} />
        <Route path="/services/smart-contract/select-contract" element={<SelectContract />} />
        <Route path="/services/smart-contract/set-subject" element={<SetSubject />} />
        <Route path="/services/smart-contract/set-1st-party" element={<Set1stParty />} />
        <Route path="/services/smart-contract/set-2nd-party" element={<Set2ndParty />} />
        <Route path="/services/smart-contract/set-condition" element={<SetCondition />} />
      </Routes>
    </ThemeProvider>
  );
}
