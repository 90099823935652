/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import bgImage from "assets/images/api-card.jpg";


function Information() {
  return (
    <MKBox component="section" py={10}>
      <Container>
        <Grid container spacing={10} alignItems="center">
          <Grid item xs={12} lg={4}>
            <CenteredBlogCard
              image={bgImage}
              title="Process Monitoring with APIs"
              description="By connecting to the contract and the shipment process, this integrated 
              system ensures that every party is informed promptly about the fulfillment or non-fulfillment 
              of contract terms, streamlining international trade operations."
              action={{
                type: "internal",
                route: "../pages/landing-pages/select-contract",
                color: "info",
                label: "Connect Later!",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={8} sx={{ ml: "auto", mt: { xs: 10, lg: 0 } }}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Real-Time Contract Surveillance"
                    description=" Ensure the fulfillment of contract terms and conditions instantly, reducing the risk of disputes."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Seamless Stakeholder Communication"
                    description="Facilitate transparent communication by automatically updating all involved parties on contract status changes."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Enhanced Visibility"
                    description="Gain comprehensive insights into shipment and contract statuses for informed decision-making and improved risk management."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Efficient Cross-Border Operations"
                    description="Simplify international trade processes by streamlining monitoring and reporting, reducing complexities and increasing efficiency."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
