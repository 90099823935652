/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <MKBox component="section" py={10}>
      <Container>
        <Grid container spacing={10} alignItems="center">
          <Grid item xs={12} lg={4}>
            <CenteredBlogCard
              image="https://img.freepik.com/free-photo/composition-with-person-working-human-resources_23-2150447642.jpg?w=740&t=st=1691280168~exp=1691280768~hmac=5d26e5d4a182e3e427c0ac4b7cbf75c9ac9a8e0c3b98a9c5ef5af4603e426f96"
              title="Customizable smart contracts"
              description="Our platform specializes in creating smart contracts that cater to the unique demands of diverse goods and transportation methods. With a focus on precision and customization, our smart contracts are designed around four essential parameters:"
              action={{
                type: "internal",
                route: "./select-contract",
                color: "info",
                label: "Custom Yours",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={8} sx={{ ml: "auto", mt: { xs: 10, lg: 0 } }}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Origins and Destinations"
                    description=" Whether you're dealing with government entities, private corporations, or individual traders, our smart contracts adapt to the type of importer and exporter involved. This customization ensures that every contract aligns perfectly with the parties' legal and operational requirements."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Cargo Type and Conditions"
                    description="We understand that different goods demand specific handling, storage, and transportation conditions. Our smart contracts take into account the unique needs of each cargo, including the necessary licenses and certifications, ensuring your shipments are always compliant."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Transport Channels"
                    description="Choose between road, rail, sea, or air transport channels, and our smart contracts will be tailored accordingly. From landlocked routes to transoceanic voyages, our solution adapts to your chosen logistics strategy."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Cargo Size and Weight"
                    description="Whether you're shipping small parcels or massive cargo loads, our smart contracts are designed to accurately reflect the scale of your operations. This ensures that your agreements remain proportional to the magnitude of your trade activities."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
