import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/examples/blog2.jpg";
import ContractCosts from "./ContractCosts";

function ContractCondition({ onNextClick, onBackClick }) {
  // Initialize contractData4th with initial values
  const initialContractData4th = {
    steps: [
      {
        title: "",
        amount: "",
        payer: "",
        receiver: "",
        paymentCondition: "AfterConfirmation",
        daysAfterConfirmation: "",
        confirmationMethod: "UniqueCodes",
        penalty: "NoPenalty",
        penaltyAmount: "",
        penaltyReceiver: "",
      },
    ],
  };

  const [contractData4th, setContractData4th] = useState(initialContractData4th);

  return (
    <MKBox component="section" py={{ xs: 0, lg: 8 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Set Smart Contract Type-A
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Craft your customized Type-A smart contract effortlessly. Define parties,
                      cargo specifics, and confirmation protocols to streamline your secure
                      international trade agreements.
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      3/3. The Journey
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      Define the steps of the journey in order, define the cost, the executor and
                      the payer, and define the non-performance penalty structure.
                    </MKTypography>
                    <Grid
                      container
                      item
                      xs={0}
                      md={0}
                      justifyContent="center"
                      textAlign="right"
                      ml="auto"
                    >
                      <ContractCosts
                        contractData4th={contractData4th}
                        setContractData4th={setContractData4th}
                      />
                    </Grid>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={10}
                        md={6}
                        justifyContent="flex-start"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton variant="outlined" color="info" onClick={onBackClick}>
                          Back
                        </MKButton>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton variant="gradient" color="info" onClick={onNextClick}>
                          Next
                        </MKButton>
                      </Grid>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

ContractCondition.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

export default ContractCondition;
