/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
const imagesPrefix1 =
  "https://img.freepik.com/premium-photo/handshake-two-businessmen-who-enters-into-contract-protect-cyber-security-international-company-padlock-hologram-icons-table-with-documents_269648-2167.jpg?size=626&ext=jpg&uid=P73864433&ga=GA1.1.69153235.1671701237&semt=ais";
  const imagesPrefix2 =
  "https://img.freepik.com/free-photo/application-programming-interface-hologram_23-2149092255.jpg?size=626&ext=jpg&uid=P73864433&ga=GA1.1.69153235.1671701237&semt=ais";
  const imagesPrefix3 =
  "https://img.freepik.com/premium-photo/smart-warehouse-management-system-with-innovative-internet-things-technology_31965-51901.jpg?size=626&ext=jpg&uid=P73864433&ga=GA1.1.69153235.1671701237&semt=ais";
  const imagesPrefix4 =
  "https://img.freepik.com/premium-photo/tether-usdt-cryptocurrency-symbol-logo-business-financial-concept-hand-with-smartphone-screen-with-crypto-icon-close-up_526934-3391.jpg?size=626&ext=jpg&uid=P73864433&ga=GA1.1.69153235.1671701237&semt=ais";
  
 // import bgImage from "assets/images/bg-about-us.jpg";
export default [
  {
    title: "Solve the challenges",
    description: "Smarter contract tools are with you in all stages of the international trade",
    items: [
      {
        image: `${imagesPrefix1}/features.jpg`,
        name: "Specialized Smart Contract Generator with AI Recommender",
        //count: 10,
        route: "/services/smart-contract",
      },
      {
        image: `${imagesPrefix2}/features.jpg`,
        name: "API based process monitoring platform",
        //count: 14,
        route: "/services/api-based",
      },
      {
        image: `${imagesPrefix3}/headers.jpg`,
        name: "IoT Tracker Package",
        //count: 10,
        route: "/services/iot-tracker",
      },
      {
        image: `${imagesPrefix4}/features.jpg`,
        name: "Automated Cryptocurrency Payment System",
        //count: 14,
        route: "/services/auto-crypto",
      },
      /*{
        image: `${imagesPrefix}/pricing.jpg`,
        name: "Pricing",
        count: 8,
        pro: true,
      },
      {
        image: `${imagesPrefix}/faq.jpg`,
        name: "FAQ",
        count: 1,
        pro: true,
      },
      {
        image: `${imagesPrefix}/blogs.jpg`,
        name: "Blog Posts",
        count: 11,
        pro: true,
      },
      {
        image: `${imagesPrefix}/testimonials.jpg`,
        name: "Testimonials",
        count: 11,
        pro: true,
      },
      {
        image: `${imagesPrefix}/teams.jpg`,
        name: "Teams",
        count: 6,
        pro: true,
      },
      {
        image: `${imagesPrefix}/stats.jpg`,
        name: "Stats",
        count: 3,
        pro: true,
      },
      {
        image: `${imagesPrefix}/call-to-action.jpg`,
        name: "Call to Actions",
        count: 8,
        pro: true,
      },
      {
        image: `${imagesPrefix}/projects.jpg`,
        name: "Applications",
        count: 6,
        pro: true,
      },
      {
        image: `${imagesPrefix}/logo-area.jpg`,
        name: "Logo Areas",
        count: 4,
        pro: true,
      },
      {
        image: `${imagesPrefix}/footers.jpg`,
        name: "Footers",
        count: 10,
        pro: true,
      },
      {
        image: `${imagesPrefix}/general-cards.jpg`,
        name: "General Cards",
        count: 9,
        pro: true,
      },
      {
        image: `${imagesPrefix}/content-sections.jpg`,
        name: "Content Sections",
        count: 8,
        pro: true,
      },*/
    ],
  },
 /* {
    title: "Your Solution",
    description: "How can Smarter Contract solutions improve your business?",
    items: [
      {
        image: `${imagesPrefix}/navbars.jpg`,
        name: "Importer",
        route: "/sections/navigation/navbars",
      },
      {
        image: `${imagesPrefix}/nav-tabs.jpg`,
        name: "Exporter",
        route: "/sections/navigation/nav-tabs",
      },
      {
        image: `${imagesPrefix}/pagination.jpg`,
        name: "3PLs (Third-party logistics)",
        route: "/sections/navigation/pagination",
      },
    ],
  },/*
  /*{
    title: "Input Areas",
    description: "50+ elements that you need for text manipulation and insertion",
    items: [
      {
        image: `${imagesPrefix}/newsletters.jpg`,
        name: "Newsletters",
        count: 6,
        pro: true,
      },
      {
        image: `${imagesPrefix}/contact-sections.jpg`,
        name: "Contact Sections",
        count: 8,
        pro: true,
      },
      {
        image: `${imagesPrefix}/forms.jpg`,
        name: "Forms",
        count: 3,
        route: "/sections/input-areas/forms",
      },
      {
        image: `${imagesPrefix}/inputs.jpg`,
        name: "Inputs",
        count: 6,
        route: "/sections/input-areas/inputs",
      },
    ],
  },
  {
    title: "Attention Catchers",
    description: "20+ Fully coded components that popup from different places of the screen",
    items: [
      {
        image: `${imagesPrefix}/alerts.jpg`,
        name: "Alerts",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: `${imagesPrefix}/toasts.jpg`,
        name: "Notifications",
        count: 3,
        pro: true,
      },
      {
        image: `${imagesPrefix}/popovers.jpg`,
        name: "Tooltips & Popovers",
        count: 2,
        route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: `${imagesPrefix}/modals.jpg`,
        name: "Modals",
        count: 5,
        route: "/sections/attention-catchers/modals",
      },
    ],
  },
  {
    title: "Elements",
    description: "80+ carefully crafted small elements that come with multiple colors and shapes",
    items: [
      {
        image: `${imagesPrefix}/buttons.jpg`,
        name: "Buttons",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: `${imagesPrefix}/avatars.jpg`,
        name: "Avatars",
        count: 2,
        route: "/sections/elements/avatars",
      },
      {
        image: `${imagesPrefix}/dropdowns.jpg`,
        name: "Dropdowns",
        count: 2,
        route: "/sections/elements/dropdowns",
      },
      {
        image: `${imagesPrefix}/switch.jpg`,
        name: "Toggles",
        count: 2,
        route: "/sections/elements/toggles",
      },
      {
        image: `${imagesPrefix}/social-buttons.jpg`,
        name: "Social Buttons",
        count: 2,
        pro: true,
      },
      {
        image: `${imagesPrefix}/breadcrumbs.jpg`,
        name: "Breadcrumbs",
        count: 1,
        route: "/sections/elements/breadcrumbs",
      },
      {
        image: `${imagesPrefix}/badges.jpg`,
        name: "Badges",
        count: 3,
        route: "/sections/elements/badges",
      },
      {
        image: `${imagesPrefix}/progress.jpg`,
        name: "Progress Bars",
        count: 4,
        route: "/sections/elements/progress-bars",
      },
      {
        image: `${imagesPrefix}/tables.jpg`,
        name: "Tables",
        count: 3,
        pro: true,
      },
      {
        image: `${imagesPrefix}/typography.jpg`,
        name: "Typography",
        count: 2,
        route: "/sections/elements/typography",
      },
    ],
  },*/
];
