import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormLabel,
  FormGroup,
  Grid,
} from "@mui/material";

const QualityStandards = ({ contractData, setContractData }) => {
  const [requiresStandards, setRequiresStandards] = useState("");
  const [selectedStandards, setSelectedStandards] = useState([]);

  const qualityStandards = [
    "ISO 9001",
    "ISO 14001",
    "CE Marking",
    "RoHS",
    "UL Certification",
    "FDA Approval",
    "GOTS",
    // Add more standards here
  ];

  const handleRequiresStandardsChange = (event) => {
    const value = event.target.value;
    setRequiresStandards(value);
    const updatedContractData = { ...contractData, standard: { ...contractData.standard, required: value } };
    setContractData(updatedContractData);

    // Clear the selected standards when 'No' is selected
    if (value === "no") {
      setSelectedStandards([]);
    }
  };

  const handleStandardChange = (event) => {
    const selectedStandard = event.target.value;
    let updatedSelectedStandards;

    if (selectedStandards.includes(selectedStandard)) {
      updatedSelectedStandards = selectedStandards.filter((standard) => standard !== selectedStandard);
    } else {
      updatedSelectedStandards = [...selectedStandards, selectedStandard];
    }

    setSelectedStandards(updatedSelectedStandards);

    const updatedContractData = { ...contractData, standard: { ...contractData.standard, list: updatedSelectedStandards } };
    setContractData(updatedContractData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Does your product require quality standards or certifications?
          </FormLabel>
          <RadioGroup
            aria-label="requires-standards"
            name="requires-standards"
            value={requiresStandards}
            onChange={handleRequiresStandardsChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>

      {requiresStandards === "yes" && (
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Select Quality Standards and Certifications</FormLabel>
            <FormGroup>
              {qualityStandards.map((standard) => (
                <FormControlLabel
                  key={standard}
                  control={<Checkbox checked={selectedStandards.includes(standard)} />}
                  label={standard}
                  value={standard}
                  onChange={handleStandardChange}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

QualityStandards.propTypes = {
  contractData: PropTypes.object.isRequired,
  setContractData: PropTypes.func.isRequired,
};

export default QualityStandards;
