/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
//import Author from "pages/LandingPages/Author";
import SetSubject from "pages/LandingPages/SetContract/SetContractPages/SetSubject";
//import PropTypes from "prop-types";

function SetSubjectPage() {
  return <SetSubject />;
}
/*
SetSubjectPage.propTypes = {
  contractData: PropTypes.object,
  setContractData: PropTypes.func,
};
*/
export default SetSubjectPage;