import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const Invoice = ({ contractData, setContractData }) => {
  const [items, setItems] = useState([{ description: "", quantity: 0, unitPrice: 0, discount: 0 }]);

  const [currency, setCurrency] = useState("GBP");

  const calculateItemTotal = (item) => {
    const total = item.quantity * item.unitPrice;
    const discountAmount = (total * item.discount) / 100;
    return total - discountAmount;
  };

  const calculateOverallTotal = () => {
    const itemTotals = items.map((item) => calculateItemTotal(item));
    const currencyRates = {
      GBP: 1.0,
      EUR: 1.16,
      USD: 1.37,
      CAD: 1.72,
    };
    return (
      itemTotals.reduce((total, itemTotal) => total + itemTotal, 0) * currencyRates[currency]
    ).toFixed(2);
  };

  const addItem = () => {
    setItems([...items, { description: "", quantity: 0, unitPrice: 0, discount: 0 }]);
  };

  const deleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  // Update contractData when items change
  React.useEffect(() => {
    const updatedContractData = { ...contractData };
    updatedContractData.invoice = items;
    setContractData(updatedContractData);
  }, [items, setContractData]);

  return (
    <div>
      <h2>Invoice</h2>
      <FormControl fullWidth>
        <InputLabel>Currency</InputLabel>
        <Select
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
          style={{ height: "2.5em", marginBottom: "1em" }}
        >
          <MenuItem value="EUR">EUR</MenuItem>
          <MenuItem value="GBP">GBP</MenuItem>
          <MenuItem value="USD">USD</MenuItem>
          <MenuItem value="CAD">CAD</MenuItem>
        </Select>
      </FormControl>
      {items.map((item, index) => (
        <div key={index} style={{ display: "flex", marginBottom: "8px" }}>
          <div style={{ flex: 2 }}>
            <TextField
              label="Description"
              value={item.description}
              onChange={(e) => {
                const updatedItems = [...items];
                updatedItems[index].description = e.target.value;
                setItems(updatedItems);
              }}
              fullWidth
            />
          </div>
          <div style={{ flex: 1 }}>
            <TextField
              label="Quantity"
              type="number"
              value={item.quantity}
              onChange={(e) => {
                const updatedItems = [...items];
                updatedItems[index].quantity = parseFloat(e.target.value);
                setItems(updatedItems);
              }}
              fullWidth
            />
          </div>
          <div style={{ flex: 1 }}>
            <TextField
              label={`Unit Price (${currency})`}
              type="number"
              value={item.unitPrice}
              onChange={(e) => {
                const updatedItems = [...items];
                updatedItems[index].unitPrice = parseFloat(e.target.value);
                setItems(updatedItems);
              }}
              fullWidth
            />
          </div>
          <div style={{ flex: 1 }}>
            <TextField
              label="Discount (%)"
              type="number"
              value={item.discount}
              onChange={(e) => {
                const updatedItems = [...items];
                updatedItems[index].discount = parseFloat(e.target.value);
                setItems(updatedItems);
              }}
              fullWidth
            />
          </div>
          <div style={{ flex: 1 }}>
            <div>{calculateItemTotal(item).toFixed(2)}</div>
          </div>
          <div style={{ flex: 1 }}>
            <Button onClick={() => deleteItem(index)}>Delete</Button>
          </div>
        </div>
      ))}
      <Button variant="contained" color="primary" onClick={addItem}>
        Add Item
      </Button>
      <div
        style={{
          border: "1px solid #000",
          borderRadius: "8px",
          padding: "8px",
          marginTop: "16px",
        }}
      >
        <strong>Overall Total:</strong> {calculateOverallTotal()} {currency}
      </div>
    </div>
  );
};

Invoice.propTypes = {
  contractData: PropTypes.object.isRequired,
  setContractData: PropTypes.func.isRequired,
};

export default Invoice;
