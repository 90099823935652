import React from "react";
import PropTypes from "prop-types";
import { TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";

function ContractCosts({ contractData4th, setContractData4th }) {
  const { steps } = contractData4th;

  const handleAddStep = () => {
    setContractData4th((prevContractData) => ({
      ...prevContractData,
      steps: [
        ...prevContractData.steps,
        {
          title: "",
          amount: "",
          payer: "",
          receiver: "",
          paymentCondition: "AfterConfirmation",
          daysAfterConfirmation: "",
          confirmationMethod: "UniqueCodes",
          penalty: "NoPenalty",
          penaltyAmount: "",
          penaltyReceiver: "",
        },
      ],
    }));
  };

  const handleStepChange = (index, field, value) => {
    setContractData4th((prevContractData) => {
      const updatedSteps = [...prevContractData.steps];
      updatedSteps[index] = { ...updatedSteps[index], [field]: value };
      return { ...prevContractData, steps: updatedSteps };
    });
  };

  return (
    <>
      {steps.map((step, index) => (
        <div key={index}>
          <MKTypography variant="h4">Step {index + 1}</MKTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Title"
                variant="outlined"
                fullWidth
                value={step.title}
                onChange={(e) => handleStepChange(index, "title", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Amount"
                variant="outlined"
                fullWidth
                value={step.amount}
                onChange={(e) => handleStepChange(index, "amount", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Payer"
                variant="outlined"
                fullWidth
                value={step.payer}
                onChange={(e) => handleStepChange(index, "payer", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Receiver"
                variant="outlined"
                fullWidth
                value={step.receiver}
                onChange={(e) => handleStepChange(index, "receiver", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Payment Condition</InputLabel>
                <Select
                  value={step.paymentCondition}
                  onChange={(e) => handleStepChange(index, "paymentCondition", e.target.value)}
                  label="Payment Condition"
                >
                  <MenuItem value="AfterConfirmation">After Confirmation</MenuItem>
                  <MenuItem value="Immediate">Immediate</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Days After Confirmation"
                variant="outlined"
                fullWidth
                value={step.daysAfterConfirmation}
                onChange={(e) =>
                  handleStepChange(index, "daysAfterConfirmation", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Confirmation Method</InputLabel>
                <Select
                  value={step.confirmationMethod}
                  onChange={(e) =>
                    handleStepChange(index, "confirmationMethod", e.target.value)
                  }
                  label="Confirmation Method"
                >
                  <MenuItem value="UniqueCodes">Unique Codes</MenuItem>
                  <MenuItem value="Signature">Signature</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Penalty</InputLabel>
                <Select
                  value={step.penalty}
                  onChange={(e) => handleStepChange(index, "penalty", e.target.value)}
                  label="Penalty"
                >
                  <MenuItem value="NoPenalty">No Penalty</MenuItem>
                  <MenuItem value="FixedAmount">Fixed Amount</MenuItem>
                  <MenuItem value="Percentage">Percentage</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Penalty Amount"
                variant="outlined"
                fullWidth
                value={step.penaltyAmount}
                onChange={(e) => handleStepChange(index, "penaltyAmount", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Penalty Receiver"
                variant="outlined"
                fullWidth
                value={step.penaltyReceiver}
                onChange={(e) =>
                  handleStepChange(index, "penaltyReceiver", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </div>
      ))}
      <Button variant="outlined" color="primary" onClick={handleAddStep}>
        Add Step
      </Button>
    </>
  );
}

ContractCosts.propTypes = {
  contractData4th: PropTypes.object.isRequired,
  setContractData4th: PropTypes.func.isRequired,
};

export default ContractCosts;
