/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import bgImage from "assets/images/iot-card.jpg";


function Information() {
  return (
    <MKBox component="section" py={10}>
      <Container>
        <Grid container spacing={10} alignItems="center">
          <Grid item xs={12} lg={4}>
            <CenteredBlogCard
              image={bgImage}
              title="Track Your Contract"
              description="Smarter Contract collaborates with established IoT hardware suppliers, empowering our clients to choose and connect the right devices for real-time cargo condition monitoring and enhanced supply chain control."
              action={{
                type: "internal",
                route: "../pages/landing-pages/select-contract",
                color: "info",
                label: "Connect and Track it",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={8} sx={{ ml: "auto", mt: { xs: 10, lg: 0 } }}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Seamless API Integration"
                    description=" Through API connections, the IoT devices seamlessly integrate 
                    with the Smarter Contract platform, allowing for constant monitoring and instant 
                    contract condition verification."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Tailored for Sensitive Cargo"
                    description="The IoT Tracking Package is designed to meet the specific needs of sensitive 
                    cargo types like pharmaceuticals, meat, and perishable foods, ensuring their safe transport 
                    and storage."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Comprehensive IoT Solution"
                    description="This package includes a suite of IoT devices, such as sensors, 
                    data aggregators, processors, and transmitters, providing a comprehensive solution for 
                    real-time cargo monitoring."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Hardware Freedom"
                    description="Smarter Contract collaborates with established IoT hardware suppliers, 
                    giving customers the flexibility to choose the most suitable IoT devices and ensuring 
                    compatibility with their smart contracts."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
