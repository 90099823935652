/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
//import MKBadge from "components/MKBadge";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Link } from "react-router-dom";
//import Typography from "layouts/sections/elements/typography/components/TypographyRoboto";

// Image
//import bgImage from "assets/images/illustrations/illustration-reset.jpg";

//feature
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

function SelectContract() {
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "../../../sign-in",
            label: "sign in",
            color: "info",
          }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid
          item
          xs={10}
          sm={10}
          md={7}
          lg={4}
          xl={4}
          ml={{ xs: "auto", lg: 0 }}
          mr={{ xs: "auto", lg: 0 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Type-A
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Basic smart contract for up to 3 parties with manual validation.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <MKBox component="section" py={{ xs: 12, md: 0 }}>
                      <Container>
                        <Grid container alignItems="center">
                          <Grid
                            xs={0}
                            lg={0}
                            sx={{
                              ml: { xs: -4, lg: "auto" },
                              mt: { xs: 6, lg: 0 },
                            }}
                          >
                            <Stack>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small">mediation</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  Up to 3 Parties
                                </MKTypography>
                              </MKBox>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="4rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small">settings_overscan</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  Validation based on parties confirmation
                                </MKTypography>
                              </MKBox>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small">token</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  Without Automated Payment
                                </MKTypography>
                              </MKBox>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Container>
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton
                    component={Link}
                    to="../services/smart-contract/set-subject"
                    type="submit"
                    variant="gradient"
                    color="info"
                  >
                    EUR55-Select
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          md={7}
          lg={4}
          xl={4}
          ml={{ xs: "auto", lg: 0 }}
          mr={{ xs: "auto", lg: 0 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Type-B
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Advanced smart contract for up to 7 parties with API-based validation.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <MKBox component="section" py={{ xs: 12, md: 0 }}>
                      <Container>
                        <Grid container alignItems="center">
                          <Grid
                            xs={0}
                            lg={0}
                            sx={{
                              ml: { xs: -4, lg: "auto" },
                              mt: { xs: 6, lg: 0 },
                            }}
                          >
                            <Stack>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small">mediation</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  Up to 7 Parties
                                </MKTypography>
                              </MKBox>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="4rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small">settings_overscan</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  Validation based on API connection
                                </MKTypography>
                              </MKBox>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small">token</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  Without Automated Payment
                                </MKTypography>
                              </MKBox>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Container>
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info"
                    to="../services/smart-contract/set-subject"
                  >
                    EUR88-Select
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          md={7}
          lg={4}
          xl={4}
          ml={{ xs: "auto", lg: 0 }}
          mr={{ xs: "auto", lg: 0 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Type-C
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                premium smart contract for up to 15 parties with API-based validation and automatade
                payments.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <MKBox component="section" py={{ xs: 12, md: 0 }}>
                      <Container>
                        <Grid container alignItems="center">
                          <Grid
                            xs={0}
                            lg={0}
                            sx={{
                              ml: { xs: -4, lg: "auto" },
                              mt: { xs: 6, lg: 0 },
                            }}
                          >
                            <Stack>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small">mediation</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  Up to 15 Parties
                                </MKTypography>
                              </MKBox>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="4rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small">settings_overscan</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  Validation based on API connection
                                </MKTypography>
                              </MKBox>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small">token</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  Automated Payment
                                </MKTypography>
                              </MKBox>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Container>
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info" disable="true"
                    to="../services/smart-contract/set-subject"
                  >
                    EUR118-Select
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SelectContract;
